const registerEventListeners_MenuLink = () => {
    const menuLink = document.getElementById("menu-link");
    if (menuLink) {
        menuLink.addEventListener("click", (event) => {
            event.preventDefault();

            const menu = document.getElementById("site-nav");
            if (menu) {
                menu.classList.toggle("--active");
            }
        });
    }
};

document.addEventListener("DOMContentLoaded", () => {
    registerEventListeners_MenuLink();
});
