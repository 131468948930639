// Load lazysizes library
require("lazysizes");

const nodeIsElemment = (node: Node): node is HTMLElement => {
    return node.nodeType === 1;
};

/**
 * Finds all current and future elements matching the given selector and calls the callback
 * function for each element found.
 */
export const decorateElements = async (
    selector: string,
    cb: (elemBatch: HTMLElement[]) => void,
) => {
    // Generate a unique ID for this decorator instance
    const yieldHistory: Set<HTMLElement> = new Set();

    const yieldBatch = (elemBatch: HTMLElement[]) => {
        // Filter out elements that have already been decorated
        elemBatch = elemBatch.filter((elem) => {
            return !yieldHistory.has(elem);
        });
        // Tag each element so that we don't decorate it again
        for (const elem of elemBatch) {
            yieldHistory.add(elem);
        }
        // Send the batch
        cb(elemBatch);
    };

    // Decorate all the elements that already exist in the DOM
    yieldBatch(Array.from(document.querySelectorAll<HTMLElement>(selector)));

    // Build a mutation observer to watch for any new elements added to the DOM
    const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
            const addedNodes = Array.from(mutation.addedNodes);
            for (const node of addedNodes) {
                if (nodeIsElemment(node)) {
                    const elem = node.parentElement || node;
                    yieldBatch(
                        Array.from(
                            elem.querySelectorAll<HTMLElement>(selector),
                        ),
                    );
                }
            }
        }
    });

    // Start observing the target node for configured mutations
    observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true,
    });
};

const markNodeAsVisible = (elem: HTMLElement) => {
    elem.classList.add("lazy-background--visible");
    const backgroundImage = elem.dataset.backgroundImage;
    if (backgroundImage) {
        elem.style.backgroundImage = `url(${backgroundImage})`;
    }
};

decorateElements(".lazy-background", (elems) => {
    // Register the intersection observers to load this background image when it becomes visible
    // If the UA is headless chrome (Puppeteer) load everything immediately.
    if (
        "IntersectionObserver" in window &&
        !window.navigator.userAgent.includes("HeadlessChrome")
    ) {
        const lazyBackgroundObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    markNodeAsVisible(entry.target as HTMLElement);
                    lazyBackgroundObserver.unobserve(entry.target);
                }
            });
        });
        elems.forEach((elem) => {
            lazyBackgroundObserver.observe(elem);
        });
    } else {
        elems.forEach(markNodeAsVisible);
    }
});
