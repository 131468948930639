// Import polyfills and setup error reporting system
import "./_bootstrap";

// Define window globals we expect to exist
declare global {
    interface Window {}
}

// Remove no-js class from HTML tag
const htmlElem = document.querySelector("html");
if (htmlElem) {
    htmlElem.classList.remove("no-js");
}

// Load CSS
require("./styles/main.scss");

// Load Site Components
require("./helpers/index");
