import Flickity from "flickity";

const buildCarousel = () => {
    const carouselSlides = document.querySelector(".hero");
    const carouselSlidesCount =
        document.querySelectorAll(".hero__background").length;

    if (carouselSlides && carouselSlidesCount > 1) {
        const carousel = new Flickity(carouselSlides, {
            // options
            cellAlign: "left",
            contain: true,
            prevNextButtons: false,
        });

        carousel.resize();
    }
};

document.addEventListener("DOMContentLoaded", () => {
    buildCarousel();
});
