const registerEventListeners_Accordion = () => {
    Array.from(
        document.querySelectorAll<HTMLElement>(".accordion__trigger"),
    ).forEach((elem) => {
        if (elem.classList.contains("accordion__trigger--loaded")) {
            return;
        }
        elem.classList.add("accordion__trigger--loaded");
        elem.addEventListener("click", function (event) {
            event.preventDefault();
            this.classList.toggle("accordion__trigger--is-active");
            this.classList.toggle("accordion__trigger--is-inactive");
            if (!this.parentNode) {
                return;
            }
            Array.from(this.parentNode.children)
                .filter((e) => {
                    return e.classList.contains("accordion__target");
                })
                .forEach((e) => {
                    e.classList.toggle("accordion__target--is-active");
                    e.classList.toggle("accordion__target--is-hidden");
                });
        });
    });
};

const registerEventListeners_AccordionExpandLink = () => {
    const accordionExpandLink = document.getElementById("expand-all");
    const triggers = document.querySelectorAll<HTMLElement>(
        ".accordion__trigger",
    );
    const targets =
        document.querySelectorAll<HTMLElement>(".accordion__target");
    if (!accordionExpandLink) {
        return;
    }
    if (accordionExpandLink.classList.contains("expand-all--loaded")) {
        return;
    }
    accordionExpandLink.classList.add("expand-all--loaded");
    accordionExpandLink.addEventListener("click", function (event) {
        event.preventDefault();

        if (this.innerHTML === "Expand All") {
            this.innerHTML = "Collapse All";

            Array.from(triggers).forEach((elem) => {
                elem.classList.remove("accordion__trigger--is-inactive");
                elem.classList.add("accordion__trigger--is-active");
            });
            Array.from(targets).forEach((elem) => {
                elem.classList.remove("accordion__target--is-hidden");
                elem.classList.add("accordion__target--is-active");
            });
        } else {
            this.innerHTML = "Expand All";

            Array.from(triggers).forEach((elem) => {
                elem.classList.remove("accordion__trigger--is-active");
                elem.classList.add("accordion__trigger--is-inactive");
            });
            Array.from(targets).forEach((elem) => {
                elem.classList.remove("accordion__target--is-active");
                elem.classList.add("accordion__target--is-hidden");
            });
        }
    });
};

document.addEventListener("DOMContentLoaded", () => {
    registerEventListeners_Accordion();
    registerEventListeners_AccordionExpandLink();
});
