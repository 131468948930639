const registerEventListeners_ScrollToTop = () => {
    const scrollToTopButton = document.getElementById("back-to-top");
    const footerElement = document.getElementById("site-footer");

    if (scrollToTopButton && footerElement) {
        const scroll = () => {
            const scrollY =
                typeof window.scrollY === "undefined"
                    ? window.pageYOffset
                    : window.scrollY;

            if (scrollY > window.innerHeight * 1.75) {
                scrollToTopButton.classList.add("back-to-top--show");
            } else {
                scrollToTopButton.classList.remove("back-to-top--show");
            }

            const offsetTop = footerElement.offsetTop - window.innerHeight;

            if (scrollY >= offsetTop) {
                scrollToTopButton.classList.add("back-to-top--release");
            } else {
                scrollToTopButton.classList.remove("back-to-top--release");
            }
        };

        window.addEventListener("scroll", scroll);

        const scrollToTop = () => {
            const scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;

            if (scrollTop > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, scrollTop - scrollTop / 8);
            }
        };

        // When the button is clicked, run our ScrolltoTop function above!
        scrollToTopButton.onclick = (e) => {
            e.preventDefault();
            scrollToTop();
        };
    }
};

document.addEventListener("DOMContentLoaded", () => {
    registerEventListeners_ScrollToTop();
});
